import { LANGUAGE } from "@cinnamon/design-system";

export const apiRoot = window.API_ROOT || import.meta.env.VITE_API_DOMAIN;

export const APP_CONFIG = {
  LS_LANGUAGE: `language`,
  AUTH_API_ROOT: `${apiRoot}/api/v3/auth`,
  API_ROOT: `${apiRoot}/api/v3`,
  DEFAULT_LANGUAGE: LANGUAGE.JAPANESE,
  MAX_RETRIES: 1,
};

export const AUTH_API_URL = {
  LOGIN: `${APP_CONFIG.AUTH_API_ROOT}/login/`,
  REFRESH: `${APP_CONFIG.AUTH_API_ROOT}/refresh/`,
  USER_INFO: `${APP_CONFIG.AUTH_API_ROOT}/verify/`,
  FORGOT_PASSWORD: `${APP_CONFIG.AUTH_API_ROOT}/forgot-password/`,
  RESET_PASSWORD: `${APP_CONFIG.AUTH_API_ROOT}/reset-password/`,
  ISSUE_OTP: `${APP_CONFIG.AUTH_API_ROOT}/otp/`,
};

export const API_URL = {
  CHATS: `/chats/`,
  MESSAGES: `/messages/`,
  ATTACHMENTS: `/attachments/`,
  REFERENCES: `/references/`,
  GROUPS: `/groups/`,
  FOLDERS: `/collections/`,
  DOWNLOADS: `/downloads/`,
  FIGURES: `/figures/`,
  USERS: `/users/`,
  CONFIG: `/config/`,
};
