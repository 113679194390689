import { APIListQuery, KeysToSnakeCase } from "./global";
import { GroupId, GroupMinimal } from "./group";

export type UserId = string;

export interface ServerUser extends KeysToSnakeCase<User> {}

export enum USER_ROLE {
  SYSTEM_ADMIN = "SystemAdmin",
  GROUP_ADMIN = "Admin",
  USER = "User",
}

export enum USER_LANGUAGE {
  AUTO_DETECT = "auto-detect",
  JAPANESE = "ja",
  ENGLISH = "en",
}

export interface Auth2FAResponse {
  is_2fa_enabled: boolean;
}

export interface Auth2FA {
  is2faEnabled: boolean;
}

export interface User {
  id: UserId;
  username: string;
  displayName: string;
  firstName: string;
  lastName: string;
  language: USER_LANGUAGE;
  role: USER_ROLE;
  createdAt: string;
  updatedAt: string;
  lastLogin: string;
  forcePasswordChange: boolean;
  groups?: GroupMinimal[];
}

export type UserMinimal = Pick<User, "id" | "username" | "displayName" | "role">;

export interface UsersAPIListQuery extends APIListQuery {}

export interface SearchUsersAPIListQuery extends APIListQuery {
  keyword: string;
  groupId: GroupId | null;
}

export interface UserLogin {
  username: string;
  password: string;
  skip_2fa?: boolean;
}

export interface AccessToken {
  accessToken: string;
  tokenType: string;
}

export interface UserLoginResponse extends AccessToken, Auth2FA {
  is2faVerified: boolean;
}

export interface UserCreate {
  groupId?: GroupId;
  username: string;
  password: string;
  firstName: string;
  lastName?: string;
  role?: USER_ROLE;
  language?: USER_LANGUAGE;
  forcePasswordChange?: boolean;
  message?: string;
}

export interface MyAccountUpdate {
  password?: string;
  firstName?: string;
  lastName?: string;
  language?: USER_LANGUAGE;
}

export interface UserUpdate extends MyAccountUpdate {}

export interface MyPasswordUpdate {
  newPassword: string;
  otp: string;
}

export interface UserGroups {
  userId: UserId;
  groupIds: GroupId[];
}
